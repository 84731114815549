/* ==============================================
   Styles
============================================== */
/**
 * Start with variables to override defaults and cascade into all files
 */
/* ==============================================
   Variables
============================================== */
/* Brand Specific
------------------------------------ */
/**
 * Set the path to your image folder for use in the `image-ur()` function.
 * The trailing slash must be included!
 */
/* Fonts
------------------------------------ */
/* Aesthetic
------------------------------------ */
/* Workspace
------------------------------------ */
/* Preview
------------------------------------ */
/* Series
------------------------------------ */
/* Grid
------------------------------------ */
/* Islands
------------------------------------ */
/* Colors
------------------------------------ */
/* Buttons
------------------------------------ */
/* Animation Timings
------------------------------------ */
/* Base
------------------------------------ */
/* ==============================================
   Default Variables
============================================== */
/* Media Query Breakpoints
------------------------------------ */
/* Typography
------------------------------------ */
/* Z-index layers
------------------------------------ */
/* ==============================================
   Functions
============================================== */
/**
 * Shorthand for local image url path for use in backgrounds.
 *
   `background: image-url("arrow.png") no-repeat 0 0;`
 *
 */
/* ==============================================
   Mixins
============================================== */
/* Media Queries
------------------------------------ */
/**
 * Breakpoint mixin to easily add media query driven styles
 * inline with given style delcaration blocks.
 *
 * $breakpoints variable is used in helper classes to create
 * namespaced modifiers to classes. It should reflect the
 * breakpoints available in `include breakpoint()`.
 * The variable is declared in this file for clarity.
 */
/* Typography
------------------------------------ */
/**
 * Force overly long spans of text to truncate on a given boundary.
 *
   `@include truncate(400px);`
 *
 */
/* Box Model and Layout
------------------------------------ */
/**
 * Make CSS Arrows!
 * Provide a direction, overall size, arrow stretch (e.g. length)
 * and color values to turn an element into an arrow.
 *
   `@include make-arrow("left", 10px, 15px, #fff);
 *
 */
/* ==============================================
   Refresh
============================================== */
/**
 * A refreshing concoction of reset, normalize, and defacto style suggestions
 * that create solid footing for your site.
 * Contents generously influenced by Eric Meyer's reset.css and Normalize (git.io/normalize)
 */
/**
 * Whip the box model into shape with border-box!
 * http://paulirish.com/2012/box-sizing-border-box-ftw/
 */
*,
*:before {
  box-sizing: border-box; }

/**
 * General margin, padding, and border resets.
 */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0; }

/*
 * [1] - Set default font family to sans-serif.
 * [2] - Prevent iOS text size adjust after orientation change, without disabling user zoom.
 *
 */
html {
  font-size: 100%;
  /* [1] */
  text-size-adjust: 100%;
  /* [2] */ }

/**
 * Correct `block` display not defined in IE 8/9.
 */
article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, summary {
  display: block; }

/**
 * Correct `inline-block` display not defined in IE 8/9.
 */
audio,
canvas,
video {
  display: inline-block; }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Remove default list-style from list elements.
 */
ul,
ol {
  list-style: none; }

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0; }

/**
 * [1] - Remove border when inside `a` element in IE 8/9.
 * [2] - Improve IE's image resizing
 */
img {
  border: 0;
  /* [1] */
  -ms-interpolation-mode: bicubic;
  /* [2] */ }

/**
 * Correct overflow displayed oddly in IE 9.
 */
svg:not(:root) {
  overflow: hidden; }

/**
 * Correct font family set oddly in Safari 5 and Chrome.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, serif; }

/**
 * Improve readability of pre-formatted text in all browsers.
 */
pre {
  white-space: pre-wrap; }

q {
  quotes: none; }
  q:before, q:after {
    content: ''; }

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  vertical-align: baseline;
  position: relative;
  font-size: 75%;
  line-height: 0; }

sup {
  top: -.5em; }

sub {
  bottom: -.25em; }

/**
 * [1] - Address margins set differently in Firefox 4+, Safari 5, and Chrome.
 * [2] - Correct font family not being inherited in all browsers.
 * [3] - Correct font size not being inherited in all browsers.
 */
button,
input,
select,
textarea {
  margin: 0;
  /* [1] */
  font-family: inherit;
  /* [2] */
  font-size: 100%;
  /* [3] */ }

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal; }

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0; }

/**
 * [1] - Improve readability and alignment in all browsers.
 * [2] - Remove default vertical scrollbar in IE 8/9.
 * [3] - Restrict horizontal resizing.
 */
textarea {
  vertical-align: top;
  /* [1] */
  overflow: auto;
  /* [2] */
  resize: vertical;
  /* [3] */ }

/**
 * Make all labels appear clickable. Labels should include the
 * `for` attribute to allow access to the field they represent.
 */
label {
  cursor: pointer; }

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

/* ==============================================
   Base
============================================== */
/**
 * General font rules
 *
 * [1] - Disabling text-rendering until it is safe to use.
 */
html,
button,
input,
select,
textarea {
  font-family: "museo-sans", "Helvetica", "Arial", sans-serif;
  font-style: normal;
  font-size: 15px;
  font-weight: 400;
  color: #424953;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

/**
 * [1] - Remove gap below images
 * [2] - Prevent images from exceding their containers
 */
img {
  vertical-align: middle;
  /* [1] */
  max-width: 100%;
  /* [2] */ }

a {
  text-decoration: none; }

/* ==============================================
 * HELPERS
 * ============================================== */
/**
 * Display helpers based on media queries
 *
   `@extend %show--mobile-only;`
 *
 */
.hidden,
.show--min-only,
.show--mobile-down,
.show--mobile-only,
.show--mobile-up,
.show--tablet-down,
.show--tablet-only,
.show--tablet-up,
.show--desktop-up,
.show--desktop-down,
.show--desktop-only,
.show--widescreen-up,
.show--widescreen-only,
.show--max-only {
  display: none; }

@media only screen and (max-width: 480px) {
  .show--min-only {
    display: block; } }

@media only screen and (max-width: 718px) {
  .show--mobile-down {
    display: block; } }

@media only screen and (min-width: 481px) and (max-width: 718px) {
  .show--mobile-only {
    display: block; } }

@media only screen and (min-width: 481px) {
  .show--mobile-up {
    display: block; } }

@media only screen and (max-width: 1150px) {
  .show--tablet-down {
    display: block; } }

@media only screen and (min-width: 719px) and (max-width: 1150px) {
  .show--tablet-only {
    display: block; } }

@media only screen and (min-width: 719px) {
  .show--tablet-up {
    display: block; } }

@media only screen and (min-width: 1151px) {
  .show--desktop-up {
    display: block; } }

@media only screen and (max-width: 1296px) {
  .show--desktop-down {
    display: block; } }

@media only screen and (min-width: 1151px) and (max-width: 1296px) {
  .show--desktop-only {
    display: block; } }

@media only screen and (min-width: 1297px) {
  .show--widescreen-up {
    display: block; } }

@media only screen and (min-width: 1297px) and (max-width: 1550px) {
  .show--widescreen-only {
    display: block; } }

@media only screen and (min-width: 1550px) {
  .show--max-only {
    display: block; } }

/**
 * Micro clearfix, as per: css-101.org/articles/clearfix/latest-new-clearfix-so-far.php
 * Extend the clearfix class with Sass to avoid the `.cf` class appearing over markup
 *
   `@extend %cf;`
 *
 */
.cf:after {
  content: '';
  display: table;
  clear: both; }

/**
 * Image replacement technique to hide text when adding a background image.
 * This technique is favorable over `text-indent: -9999px;` because we avoid
 * drawing very wide boxes to accomodate the indent.
 *
 * Note: IE10 does not like the use of the image technique with pseudo elements
 *       that do not have a declared height. An example of this would be
 *       problems with CSS arrow pseudo elements with parents that contain text.
 *
   `@extend %ir;`
 *
 */
.ir {
  border: 0;
  overflow: hidden;
  font: 0/0 a;
  color: transparent;
  text-shadow: none; }
  .ir:before {
    content: '';
    display: block;
    width: 0;
    height: 100%; }

/**
 * Visually hide elements, but allow them to be read by screen readers.
 *
   `@extend %vh;`
 *
 */
.vh {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0); }

/**
 * Scrollable helper which includes smooth iOS scrolling
 */
.scrollable {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }

/* Objects
------------------------------------ */
/* ==============================================
   Typography
============================================== */
/* Headers
------------------------------------ */
.Title {
  font-size: 1.33rem;
  font-weight: 600;
  color: #424953; }

.Title--caps {
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: .8px;
  text-transform: uppercase; }

/* Text
------------------------------------ */
strong {
  font-weight: 600; }

i {
  font-style: italic; }

p {
  line-height: 1.6; }

.t--sm {
  font-size: .88rem; }

.t--med {
  font-size: .94rem; }

.t--lrg {
  font-size: 1.07rem; }

.t--xl {
  font-size: 1.15rem; }

.t--wm {
  font-weight: 500; }

.t--wb {
  font-weight: 700; }

/* Links
------------------------------------ */
.Link {
  color: #2389c7;
  transition: color .15s ease; }
  .Link.is-active {
    color: #424953; }

.Link--hover {
  color: #2389c7;
  cursor: pointer;
  transition: all .2s ease; }
  .Link--hover:hover {
    color: #72a7c7; }

.Link--subtle {
  color: #424953;
  cursor: pointer;
  transition: all .2s ease; }
  .Link--subtle:hover {
    color: #2389c7; }
    .Link--subtle:hover .Icon__fill {
      fill: #2389c7; }

/* Color
------------------------------------ */
.c--black {
  color: black; }

.c--dark {
  color: #424953; }

.c--dark-gray {
  color: #636b75; }

.c--gray {
  color: #aeaeae; }

.c--light-gray {
  color: #a7a7a7; }

.c--danger {
  color: #c72323; }

/* Misc
------------------------------------ */
.Tag {
  display: inline-block;
  padding: 3px 6px;
  background: #424953;
  font-size: .87rem;
  letter-spacing: .8px;
  color: #fff;
  text-transform: uppercase;
  border-radius: 3px; }

.Accent {
  font-size: .87rem;
  color: rgba(0, 0, 0, 0.35); }

/* List
------------------------------------ */
.bullets {
  margin-left: 21px;
  list-style: square; }

/* Borders
------------------------------------ */
.bt--gray {
  border-top: 1px solid #e8e8e8 !important; }

.bt--gray--lrg {
  border-top: 2px solid #e8e8e8 !important; }

/* ==============================================
   Buttons
============================================== */
.Button {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding: 11px 12px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: .87rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: .5px;
  color: #424953;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 5px;
  transition: all .2s ease;
  user-select: none; }
  .Button:hover {
    border-color: #a8a8a8; }
  .Button:focus {
    outline: none; }
  .Button:active {
    outline: none; }
  .Button[disabled] {
    color: #ccc;
    cursor: default;
    pointer-events: none; }
    .Button[disabled] .Icon {
      opacity: .2; }
  .Button .Icon {
    position: absolute;
    left: 12px;
    pointer-events: none; }
  .Button .Icon--arrowSm {
    display: inline-block;
    vertical-align: middle;
    position: static; }
    .Button .Icon--arrowSm .Icon__svg {
      display: block; }
  .Button.is-active {
    border-color: #e8e8e8;
    color: #2389c7; }
    .Button.is-active .Icon__fill {
      fill: #2389c7; }
  .Button.is-loading {
    padding-right: 46px; }
    .Button.is-loading.Button--wide {
      padding-right: 30px; }

/* Size Variations
------------------------------------ */
.Button--med {
  padding: 13px 26px 12px; }

.Button--wide {
  min-width: 130px;
  text-align: center; }

.Button--full {
  width: 100%; }

.Button--unselected {
  background: #f6f7fa; }

/* Cancel Actions
------------------------------------ */
/* Color Variations
------------------------------------ */
.Button--primary {
  border-color: #72a7c7;
  background: #72a7c7;
  font-weight: 700;
  color: #fff; }
  .Button--primary:hover {
    border-color: #72a7c7;
    background: #549ac4; }
  .Button--primary:active {
    background: #4f87a8; }
  .Button--primary .Icon__fill {
    fill: #fff; }
  .Button--primary[disabled] {
    color: rgba(255, 255, 255, 0.2); }

.Button--danger {
  border-color: #b20000;
  background: #b20000;
  font-weight: 700;
  color: #fff; }
  .Button--danger:hover {
    border-color: #9a0909;
    background: #9a0909; }

.Button--stroke {
  border-color: #e8e8e8;
  background: transparent; }
  .Button--stroke:hover {
    border-color: #424953;
    background: transparent; }

/* Loading
------------------------------------ */
.Button__text {
  position: relative; }
  .Button__text:after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    right: -26px;
    width: 12px;
    height: 12px;
    border: 2px solid rgba(255, 255, 255, 0.8);
    border-right-color: transparent;
    opacity: 0;
    border-radius: 50%;
    transition: opacity .1s ease;
    mask-image: linear-gradient(to bottom right, #000, rgba(0, 0, 0, 0)); }
  .Button.is-loading .Button__text:after {
    opacity: 1;
    animation: rotate .7s linear infinite;
    transition-delay: .1s; }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.margin-right-btn {
  margin-right: 10px; }

.margin-left-btn {
  margin-left: 10px; }

/* ==============================================
   Labels
============================================== */
.Label {
  display: block;
  margin: 0 0 8px;
  font-size: .88rem;
  line-height: 1.3;
  color: #888d8f; }

.Label--simple {
  display: block;
  font-size: 1rem; }

.Label--danger {
  color: #b20000; }

.Label__optional {
  margin-left: 8px;
  font-style: italic;
  font-size: .81rem;
  font-weight: 300;
  letter-spacing: 0;
  color: #999;
  text-transform: none; }

.LabelSpacingHelper {
  margin-top: 25px; }

/* ==============================================
   Input
============================================== */
.placeholder {
  color: #aeaeae; }

::-webkit-input-placeholder {
  color: #aeaeae; }

:-moz-placeholder {
  color: #aeaeae; }

::-moz-placeholder {
  color: #aeaeae; }

:-ms-input-placeholder {
  color: #aeaeae; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none; }

.InputWrapper {
  position: relative; }

.Input {
  display: block;
  width: 100%;
  padding: 12px;
  border: 1px solid #e8e8e8;
  background: #fff;
  box-shadow: none;
  font-size: 1rem;
  border-radius: 5px;
  transition: border .3s ease;
  appearance: none; }
  .Input:hover {
    border-color: #d6d6d6; }
  .Input:focus {
    border-color: #b3b3b3;
    background: #fff;
    cursor: text;
    outline: 0; }
  .Input[readonly] {
    opacity: .5; }
    .Input[readonly]:focus, .Input[readonly]:hover {
      border-color: #e8e8e8; }
  .Input::-webkit-contacts-auto-fill-button {
    display: none !important;
    visibility: hidden;
    pointer-events: none; }
  .Input.is-not-available, .Input.is-not-available:hover, .Input[data-ui-state='error'] {
    border-color: #b82502; }

.Input--sm {
  padding: 9px 12px; }

.Input--xs {
  padding: 6px 12px; }

.Input--wsm {
  max-width: 150px; }

.Input--wmax {
  max-width: 400px; }

.Input--wmax2 {
  max-width: 100px;
  margin: auto; }

.Input--wmax3 {
  max-width: 50px;
  margin: auto; }

.Input--hlrg {
  height: 44px; }

.Input__action {
  position: absolute;
  top: 14px;
  right: 14px;
  z-index: 2; }

/* Input Checkable
------------------------------------ */
.InputCheckable .Checkbox {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2; }

.InputCheckable .Checkbox__box {
  background: transparent; }

.InputCheckable .Input {
  padding-left: 48px; }

.InputToggleable {
  padding-left: 48px; }
  .InputToggleable .Checkbox {
    position: absolute;
    top: 8px;
    left: 10px;
    z-index: 2; }

/* Mask
------------------------------------ */
.InputMask {
  display: inline-block;
  position: relative; }
  .InputMask .Input {
    font-weight: 500; }

.MaskSuffix {
  position: absolute;
  top: 0;
  right: 0;
  padding: 13px 12px;
  color: #aeaeae;
  transition: color .2s ease;
  pointer-events: none; }
  [data-ui-focused='true'] .MaskSuffix {
    color: #282828; }

.MaskSuffix__input {
  opacity: 0; }

.MaskPrefix {
  display: block;
  position: absolute;
  left: 0;
  padding: 13px 12px;
  color: #aeaeae;
  transition: color .2s ease;
  pointer-events: none; }
  .InputSymbol .MaskPrefix {
    left: 34px; }
  .has-value .MaskPrefix,
  .is-focused .MaskPrefix {
    color: #424953; }

.MaskPrefix--sm {
  padding: 10px 12px; }

/* Textarea
------------------------------------ */
.Textarea {
  height: 156px;
  padding-right: 40px;
  box-shadow: none;
  line-height: 1.5;
  resize: none;
  appearance: none; }

.Textarea--sm {
  height: 88px; }

.Textarea--read-sm {
  min-height: 88px; }

.Textarea--lrg {
  height: 265px; }

/* ==============================================
   Flash
============================================== */
.Flash {
  margin: 0 0 20px;
  padding: 12px;
  background: #b9b9b9;
  text-align: center;
  font-size: 15px;
  color: #fff;
  border-radius: 5px; }
  .Flash.is-error {
    background: #ec8b8b; }

/* ==============================================
   Staging
============================================== */
.staging-badge {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 30;
  width: 115px;
  height: 115px;
  background: url("/img/icons/staging.png") no-repeat 0 0;
  background-size: 115px 115px; }

/* ==============================================
   Utilities
============================================== */
/* Structures
------------------------------------ */
.u-af {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.u-stack {
  float: left;
  width: 100%;
  margin-right: -100%; }

.u-flx {
  display: flex; }

.u-flx-1 {
  flex: 1; }

.u-flx-2 {
  flex: 2; }

.u-flx-3 {
  flex: 3; }

.u-flx-4 {
  flex: 4; }

.u-flx-a {
  flex: auto; }

.u-flxig-2 {
  display: inline-grid;
  grid-template-columns: 50% 50%; }

.u-flxs {
  display: flex;
  align-items: flex-start;
  justify-content: center; }

.u-flxc {
  display: flex;
  align-items: center;
  justify-content: center; }

.u-flxb {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.u-flxe {
  display: flex;
  align-items: center;
  justify-content: flex-end; }

.u-flxst {
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.u-ib {
  display: inline-block;
  vertical-align: middle; }

.u-aif {
  align-items: center; }

/* Spacing
------------------------------------ */
.u-m {
  margin: 10px; }

.u-mb--nil {
  margin-bottom: 0; }

.u-mb {
  margin-bottom: 10px; }

.u-mb--xxs {
  margin-bottom: 2px; }

.u-mb--micro {
  margin-bottom: 4px; }

.u-mb--sm {
  margin-bottom: 6px; }

.u-mb--mid {
  margin-bottom: 14px; }

.u-mb--med {
  margin-bottom: 20px; }

.u-mb--lrg {
  margin-bottom: 30px; }

.u-mb--xl {
  margin-bottom: 45px; }

.u-mb--xxl {
  margin-bottom: 65px; }

.u-pb--mid {
  padding-bottom: 16px; }

.u-pb--med {
  padding-bottom: 20px; }

.u-mt {
  margin-top: 10px; }

.u-mt--xxs {
  margin-top: 2px; }

.u-mt--micro {
  margin-top: 4px; }

.u-mt--sm {
  margin-top: 6px; }

.u-mt--mid {
  margin-top: 14px; }

.u-mt--med {
  margin-top: 20px; }

.u-mt--med2 {
  margin-top: 25px; }

.u-mt--lrg {
  margin-top: 30px; }

.u-mt--xl {
  margin-top: 45px; }

.u-mt--xxl {
  margin-top: 65px; }

.u-pt--sm {
  padding-top: 9px;
  padding-bottom: 9px; }

.u-pt--mid {
  padding-top: 16px; }

.u-pt--med {
  padding-top: 20px; }

.u-mr {
  margin-right: 10px; }

.u-mr--sm {
  margin-right: 6px; }

.u-mr--mid {
  margin-right: 14px; }

.u-mr--med {
  margin-right: 20px; }

.u-mr--lrg {
  margin-right: 30px; }

.u-mr--xl {
  margin-right: 40px; }

.u-ml {
  margin-left: 10px; }

.u-ml--xs {
  margin-left: 4px; }

.u-ml--sm {
  margin-left: 6px; }

.u-ml--mid {
  margin-left: 14px; }

.u-ml--med {
  margin-left: 20px; }

.u-ml--lrg {
  margin-left: 30px; }

.u-ml--xl {
  margin-left: 40px; }

.u-pl--lrg {
  padding-left: 30px; }

/* Display
------------------------------------ */
.u-cp,
.cp {
  cursor: pointer; }

.u-wsnw {
  white-space: nowrap; }

/* Alignment
------------------------------------ */
.u-tac,
.u-align--center {
  text-align: center; }

.u-tar {
  text-align: right; }

.u-tari {
  text-align: right !important; }

.u-tal {
  text-align: left !important; }

.vertical-center {
  position: absolute;
  top: 50%;
  margin: 0;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); }

/* Dimensions
------------------------------------ */
.u-w--1-2 {
  width: 50%; }

.u-w--1-3 {
  width: 33.33%; }

.u-w--2-3 {
  width: 66.66%; }

.u-w--1-4 {
  width: 25%; }

.u-w--100 {
  width: 100%; }

/* Position
------------------------------------ */
.u-rel {
  position: relative; }

.u-z1 {
  z-index: 10; }

.u-z2 {
  z-index: 20; }

.u-z3 {
  z-index: 30; }

.u-z4 {
  z-index: 40; }

/* Opacity
------------------------------------ */
.o0 {
  opacity: 0; }

.o1 {
  opacity: 1; }

.gray--section {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  padding: 20px;
  border: 1px solid #e8e8e8;
  background: #f6f7fa;
  cursor: pointer;
  border-radius: 4px; }

.horizontal--section {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px;
  border: 1px solid #e8e8e8;
  background-color: white;
  cursor: pointer;
  border-radius: 4px; }

.gray--highlight {
  display: inline-block;
  padding: 6px;
  border: 1px solid #d8dadb;
  background: #f5f6f9;
  font-size: .88rem;
  cursor: pointer;
  border-radius: 5px; }

.square {
  display: inline-block;
  width: 18px;
  height: 18px;
  border: solid 1px #d8dadb;
  background-color: #f5f6f9;
  text-align: center;
  font-size: .88rem;
  color: #888d8f;
  border-radius: 5px; }

.upload-image--container {
  position: absolute;
  left: 0px;
  width: 115px;
  height: 115px;
  border: 2px dashed #e8e8e8;
  background-color: #f6f7fa; }

.image--container {
  position: relative;
  width: 115px;
  height: 115px; }

.CustomizeBox__asset-item {
  display: inline-block;
  vertical-align: top; }

.CustomizeBox__asset {
  width: 100%;
  border: 1px solid #e8e8e8;
  border-radius: 3px; }

.HiddenElement {
  visibility: hidden; }

.AlignColumnStart {
  display: flex;
  align-content: start;
  align-items: start;
  flex-direction: column; }

.AlignColumnCenter {
  display: flex;
  align-content: center;
  flex-direction: column; }

/* Border
------------------------------------ */
.b-t--nil {
  border-top: 0px !important; }

.b--gray {
  border: 1px solid #e8e8e8; }

/* Transition helpers
------------------------------------ */
.fade-out {
  opacity: 0;
  transition: opacity 1s; }

.deleteIcon {
  position: absolute;
  background: url("/img/icons/delete-icon.png") no-repeat 0 0;
  opacity: 0; }
  .deleteIcon .deleteIcon:hover {
    opacity: 1; }

.maxHeight {
  max-height: 100%; }

@media (max-width: 800px) {
  .u-ml--med-mobile {
    margin-left: 20px; }
  .u-mt--med-mobile {
    margin-top: 20px; }
  .u-mr--med-mobile {
    margin-right: 20px; }
  .u-mb--med-mobile {
    margin-bottom: 20px; } }

/* Sections
------------------------------------ */
/* ==============================================
   App
============================================== */
/**
 * Prevent overscrolling
 */
html {
  height: 100%;
  overflow: hidden; }

body {
  height: 100%;
  overflow: auto;
  background: #fff; }

.App {
  overflow: hidden; }

.is-debug {
  display: none; }
  .debug-enabled .is-debug {
    display: block; }

/* Loading state
------------------------------------ */
/* Offline
------------------------------------ */
/* ==============================================
   Login
============================================== */
html {
  height: 100%;
  overflow: hidden; }
  html body {
    height: 100%;
    overflow: auto;
    background-image: linear-gradient(0deg, #e2f0f3 0%, #f8f9fb 100%); }

.Login {
  max-width: 440px;
  margin: 120px auto 80px; }
  @media (max-height: 700px) {
    .Login {
      margin-top: 50px; } }

.Login__box {
  padding: 40px;
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 3px; }
