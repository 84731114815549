/* ==============================================
   Buttons
============================================== */

.Button {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding: 11px 12px;
  // Consistent spacing with wired buttons
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: .87rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: .5px;
  color: $base-color;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
  border-radius: $radius-lrg;
  transition: all .2s ease;
  user-select: none;

  &:hover {
    border-color: #a8a8a8;
  }

  &:focus {
    outline: none;
  }

  &:active {
    // transform: translate(0, 1px);
    outline: none;
  }

  &[disabled] {
    color: #ccc;
    cursor: default;
    pointer-events: none;

    .Icon {
      opacity: .2;
    }
  }

  // Let all icon events pass through to the button, such as hovers
  // NOTE: Using absolute positioning so that icon doesn't affect button height
  .Icon {
    position: absolute;
    // top: 0;
    left: 12px; // Left padding of the button
    pointer-events: none;
  }

  .Icon--arrowSm {
    display: inline-block;
    vertical-align: middle;
    position: static;

    .Icon__svg {
      display: block;
    }
  }

  &.is-active {
    border-color: #e8e8e8;
    color: $color-blue-vibrant;

    .Icon__fill {
      fill: $color-blue-vibrant;
    }
  }

  // Loading
  &.is-loading {
    padding-right: 46px;

    &.Button--wide {
      padding-right: 30px;
    }
  }
}

/* Size Variations
------------------------------------ */
.Button--med {
  padding: 13px 26px 12px;
}

.Button--wide {
  min-width: 130px;
  text-align: center;
}

.Button--full {
  width: 100%;
}

.Button--unselected {
  background: #f6f7fa;
}

/* Cancel Actions
------------------------------------ */
// .Cancel {
//   display: inline-block;
//   vertical-align: middle;
//   margin-left: 14px;
//   padding: 8px;
//   font-size: 14px;
//   font-weight: 600;
//   line-height: 1;
//   letter-spacing: .3px;
//   color: $button-bg-soft;
//   text-transform: uppercase;
//   cursor: pointer;
//   transition: color .2s ease;

//   &:hover {
//     color: $color-green;
//   }
// }

// .Cancel--sm {
//   font-size: 13px;
// }

// .Cancel--white {
//   color: #fff;

//   &:hover {
//     color: $color-green;
//   }
// }

/* Color Variations
------------------------------------ */
.Button--primary {
  border-color: $button-bg;
  background: $button-bg;
  font-weight: 700;
  color: #fff;

  &:hover {
    border-color: $button-bg;
    background: $button-bg-hover;
  }

  &:active {
    background: $button-bg-down;
  }

  .Icon__fill {
    fill: #fff;
  }

  &[disabled] {
    color: rgba(255, 255, 255, .2);
  }
}

.Button--danger {
  border-color: #b20000;
  background: #b20000;
  font-weight: 700;
  color: #fff;

  &:hover {
    border-color: #9a0909;
    background: #9a0909;
  }
}

.Button--stroke {
  border-color: #e8e8e8;
  background: transparent;

  &:hover {
    border-color: $base-color;
    background: transparent;
  }
}

/* Loading
------------------------------------ */
.Button__text {
  position: relative;

  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    right: -26px;
    width: 12px;
    height: 12px;
    border: 2px solid rgba(255, 255, 255, .8);
    border-right-color: transparent;
    opacity: 0;
    border-radius: 50%;
    transition: opacity .1s ease;
    mask-image: linear-gradient(to bottom right, #000, rgba(0, 0, 0, 0));
  }

  .Button.is-loading & {
    &:after {
      opacity: 1;
      animation: rotate .7s linear infinite;
      transition-delay: .1s;
    }
  }
}

@keyframes rotate {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.margin-right-btn {
  margin-right: 10px;
}

.margin-left-btn {
  margin-left: 10px;
}