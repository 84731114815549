/* ==============================================
   Flash
============================================== */

.Flash {
  margin: 0 0 20px;
  padding: 12px;
  background: #b9b9b9;
  text-align: center;
  font-size: 15px;
  color: #fff;
  border-radius: $radius-lrg;

  &.is-error {
    background: #ec8b8b;
  }
}
