/* ==============================================
   Input
============================================== */

// Placeholder styles
// scss-lint:disable VendorPrefix
.placeholder {color: $color-placeholder;}
::-webkit-input-placeholder {color: $color-placeholder;}
:-moz-placeholder {color: $color-placeholder;}
::-moz-placeholder {color: $color-placeholder;}
:-ms-input-placeholder {color: $color-placeholder;}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

.InputWrapper {
  position: relative;
}

.Input {
  display: block;
  width: 100%;
  padding: 12px;
  border: 1px solid $color-border-gray;
  background: #fff;
  box-shadow: none;
  font-size: 1rem;
  border-radius: $radius-lrg;
  transition: border .3s ease;
  appearance: none;

  &:hover {
    border-color: $color-border-gray-hover;
  }

  &:focus {
    border-color: $color-border-gray-active;
    background: #fff;
    cursor: text;
    outline: 0;
  }

  &[readonly] {
    opacity: .5;

    &:focus,
    &:hover {
      border-color: $color-border-gray;
    }
  }

  // HACK: GJ Safari, adding a visual element to inputs
  &::-webkit-contacts-auto-fill-button {
    // scss-lint:disable ImportantRule
    display: none !important;
    visibility: hidden;
    pointer-events: none;
  }

  // NOTE: Not available helpers for the Onboarding Save Account step
  &.is-not-available,
  &.is-not-available:hover,
  &[data-ui-state='error'] {
    border-color: $color-error;
  }
}

.Input--sm {
  padding: 9px 12px;
}

.Input--xs {
  padding: 6px 12px;
}

.Input--wsm {
  max-width: 150px;
}

.Input--wmax {
  max-width: 400px;
}

.Input--wmax2 {
  max-width: 100px;
  margin: auto;
}

.Input--wmax3 {
  max-width: 50px;
  margin: auto;
}

.Input--hlrg {
  height: 44px;
}

// .Input--disabled {
//   color: #999;
//   opacity: .6;

//   &:hover,
//   &:focus {
//     border-color: $color-fill-gray;
//     background: $color-fill-gray;
//     cursor: default;
//   }
// }

.Input__action {
  position: absolute;
  top: 14px;
  right: 14px;
  z-index: 2;
}

/* Input Checkable
------------------------------------ */
.InputCheckable {
  .Checkbox {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 2;
  }

  .Checkbox__box {
    background: transparent;
  }

  .Input {
    padding-left: 48px;
  }
}

.InputToggleable {
  padding-left: 48px;

  .Checkbox {
    position: absolute;
    top: 8px;
    left: 10px;
    z-index: 2;
  }
}

/* Mask
------------------------------------ */
.InputMask {
  display: inline-block;
  position: relative;

  .Input {
    font-weight: 500;
  }
}

.MaskSuffix {
  position: absolute;
  top: 0;
  right: 0;
  padding: 13px 12px;
  color: $color-placeholder;
  transition: color .2s ease;
  pointer-events: none;

  [data-ui-focused='true'] & {
    color: $color-black;
  }
}

.MaskSuffix__input {
  opacity: 0;
}

.MaskPrefix {
  display: block;
  position: absolute;
  left: 0;
  padding: 13px 12px;
  color: $color-placeholder;
  transition: color .2s ease;
  pointer-events: none;

  .InputSymbol & {
    left: 34px;
  }

  .has-value &,
  .is-focused & {
    color: $base-color;
  }
}

.MaskPrefix--sm {
  padding: 10px 12px;
}


/* Textarea
------------------------------------ */
.Textarea {
  height: 156px;
  padding-right: 40px;
  box-shadow: none;
  line-height: 1.5;
  resize: none;
  appearance: none;
}

.Textarea--sm {
  height: 88px;
}

.Textarea--read-sm {
  min-height: 88px;
}

.Textarea--lrg {
  height: 265px;
}
