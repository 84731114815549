/* ==============================================
   Typography
============================================== */


/* Headers
------------------------------------ */
.Title {
  font-size: 1.33rem;
  font-weight: 600;
  color: $base-color-dark;
}

.Title--caps {
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: .8px;
  text-transform: uppercase;
}


/* Text
------------------------------------ */
strong {
  font-weight: 600;
}

i {
  font-style: italic;
}

p {
  line-height: 1.6;
}

.t--sm {
  font-size: .88rem;
}

.t--med {
  font-size: .94rem;
}

.t--lrg {
  font-size: 1.07rem;
}

.t--xl {
  font-size: 1.15rem;
}

// Weights
.t--wm {
  font-weight: 500;
}

.t--wb {
  font-weight: 700;
}


/* Links
------------------------------------ */
.Link {
  color: $color-blue-vibrant;
  transition: color .15s ease;

  &.is-active {
    color: $base-color;
  }
}

.Link--hover {
  color: $color-blue-vibrant;
  cursor: pointer;
  transition: all .2s ease;

  &:hover {
    color: $color-blue;
  }
}

.Link--subtle {
  color: $base-color;
  cursor: pointer;
  transition: all .2s ease;

  &:hover {
    color: $color-blue-vibrant;

    .Icon__fill {
      fill: $color-blue-vibrant;
    }
  }
}

// .Link--block {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   z-index: $z2;
// }

/* Color
------------------------------------ */
.c--black {
  color: black;
}

.c--dark {
  color: $base-color-dark;
}

.c--dark-gray {
  color: #636b75;
}

.c--gray {
  color: $color-placeholder;
}

.c--light-gray {
  color: $color-light-gray;
}

.c--danger {
  color: $color-danger;
}


/* Misc
------------------------------------ */
.Tag {
  display: inline-block;
  padding: 3px 6px;
  background: $base-color;
  font-size: .87rem;
  letter-spacing: .8px;
  color: #fff;
  text-transform: uppercase;
  border-radius: $radius;
}

.Accent {
  font-size: .87rem;
  color: rgba(0, 0, 0, .35);
}

/* List
------------------------------------ */
.bullets {
  margin-left: 21px;
  list-style: square;
}

/* Borders
------------------------------------ */
.bt--gray {
  border-top: 1px solid $color-border-gray !important;
}
.bt--gray--lrg {
  border-top: 2px solid $color-border-gray !important;
}