/* ==============================================
   Labels
============================================== */

.Label {
  display: block;
  margin: 0 0 8px;
  font-size: .88rem;
  line-height: 1.3;
  color: #888d8f;
}

.Label--simple {
  display: block;
  font-size: 1rem;
}

.Label--danger {
  color: #b20000;
}

.Label__optional {
  margin-left: 8px;
  font-style: italic;
  font-size: .81rem;
  font-weight: 300;
  letter-spacing: 0;
  color: #999;
  text-transform: none;
}

// NOTE: Helps grid offset Label spacing to create even top spacing
.LabelSpacingHelper {
  margin-top: 25px;
}
