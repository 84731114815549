/* ==============================================
   Staging
============================================== */

.staging-badge {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z3;
  width: 115px;
  height: 115px;
  background: image-url('icons/staging.png') no-repeat 0 0;
  background-size: 115px 115px;
}
