/* ==============================================
   App
============================================== */

/**
 * Prevent overscrolling
 */
html {
  height: 100%;
  overflow: hidden;
}

body {
  height: 100%;
  overflow: auto;
  background: #fff;
}

.App {
  overflow: hidden;
}

.is-debug {
  display: none;

  .debug-enabled & {
    display: block;
  }
}

/* Loading state
------------------------------------ */
// #root {
//   &[data-ui-loading] {
//     .App {
//       opacity: 0;
//       transition: opacity 1s ease;
//     }
//   }

//   &[data-ui-loading='ready'] {
//     .App {
//       opacity: 1;
//     }
//   }
// }

/* Offline
------------------------------------ */
// .Offline {
//   display: none;
//   z-index: $z-max5;
//   background: rgba(255, 255, 255, .9);
// }

// .Offline__message {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   width: 400px;
//   margin: -100px 0 0 -200px;
//   padding: 30px;
//   border-top: 4px solid #f5ad4a;
//   background: #fff;
//   box-shadow: 0 1px 17px rgba(0, 0, 0, .2);
//   border-radius: $radius;
// }
