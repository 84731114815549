/* ==============================================
   Login
============================================== */

html {
  height: 100%;
  overflow: hidden;

  body {
    height: 100%;
    overflow: auto;
    background-image: linear-gradient(0deg, #e2f0f3 0%, #f8f9fb 100%);
  }
}

.Login {
  max-width: 440px;
  margin: 120px auto 80px;

  @media (max-height: 700px) {
    margin-top: 50px;
  }
}

.Login__box {
  padding: 40px;
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
  border-radius: $radius;
}
