/* ==============================================
   Utilities
============================================== */


/* Structures
------------------------------------ */
.u-af {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.u-stack {
  float: left;
  width: 100%;
  margin-right: -100%;
}

.u-flx {
  display: flex;
}

.u-flx-1 {
  flex: 1;
}

.u-flx-2 {
  flex: 2;
}

.u-flx-3 {
  flex: 3;
}

.u-flx-4 {
  flex: 4;
}

.u-flx-a {
  flex: auto;
}

.u-flxig-2 {
  display: inline-grid;
  grid-template-columns: 50% 50%;
}

.u-flxs {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.u-flxc {
  display: flex;
  align-items: center;
  justify-content: center;
}

.u-flxb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.u-flxe {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.u-flxst {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.u-ib {
  display: inline-block;
  vertical-align: middle;
}

.u-aif {
  align-items: center;
}

/* Spacing
------------------------------------ */

.u-m {
  margin: 10px;
}

// Bottom
.u-mb--nil {
  margin-bottom: 0;
}

.u-mb {
  margin-bottom: 10px;
}

.u-mb--xxs {
  margin-bottom: 2px;
}

.u-mb--micro {
  margin-bottom: 4px;
}

.u-mb--sm {
  margin-bottom: 6px;
}

.u-mb--mid {
  margin-bottom: 14px;
}

.u-mb--med {
  margin-bottom: 20px;
}

.u-mb--lrg {
  margin-bottom: 30px;
}

.u-mb--xl {
  margin-bottom: 45px;
}

.u-mb--xxl {
  margin-bottom: 65px;
}

.u-pb--mid {
  padding-bottom: 16px;
}

.u-pb--med {
  padding-bottom: 20px;
}

// Top
.u-mt {
  margin-top: 10px;
}

.u-mt--xxs {
  margin-top: 2px;
}

.u-mt--micro {
  margin-top: 4px;
}

.u-mt--sm {
  margin-top: 6px;
}

.u-mt--mid {
  margin-top: 14px;
}

.u-mt--med {
  margin-top: 20px;
}

.u-mt--med2 {
  margin-top: 25px;
}

.u-mt--lrg {
  margin-top: 30px;
}

.u-mt--xl {
  margin-top: 45px;
}

.u-mt--xxl {
  margin-top: 65px;
}

.u-pt--sm {
  padding-top: 9px;
  padding-bottom: 9px;
}

.u-pt--mid {
  padding-top: 16px;
}

.u-pt--med {
  padding-top: 20px;
}

// Right
.u-mr {
  margin-right: 10px;
}

.u-mr--sm {
  margin-right: 6px;
}

.u-mr--mid {
  margin-right: 14px;
}

.u-mr--med {
  margin-right: 20px;
}

.u-mr--lrg {
  margin-right: 30px;
}

.u-mr--xl {
  margin-right: 40px;
}

// Left
.u-ml {
  margin-left: 10px;
}

.u-ml--xs {
  margin-left: 4px;
}

.u-ml--sm {
  margin-left: 6px;
}

.u-ml--mid {
  margin-left: 14px;
}

.u-ml--med {
  margin-left: 20px;
}

.u-ml--lrg {
  margin-left: 30px;
}

.u-ml--xl {
  margin-left: 40px;
}

.u-pl--lrg {
  padding-left: 30px;
}

/* Display
------------------------------------ */
.u-cp,
.cp {
  cursor: pointer;
}

.u-wsnw {
  white-space: nowrap;
}


/* Alignment
------------------------------------ */
.u-tac,
.u-align--center {
  text-align: center;
}

.u-tar {
  text-align: right;
}

.u-tari {
  text-align: right !important;
}

.u-tal {
  text-align: left !important;
}

.vertical-center {
  position: absolute;
  top: 50%;
  margin: 0;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* Dimensions
------------------------------------ */
.u-w--1-2 {
  width: 50%;
}

.u-w--1-3 {
  width: 33.33%;
}

.u-w--2-3 {
  width: 66.66%;
}

.u-w--1-4 {
  width: 25%;
}

.u-w--100 {
  width: 100%;
}

/* Position
------------------------------------ */
.u-rel {
  position: relative;
}

.u-z1 {
  z-index: $z1;
}

.u-z2 {
  z-index: $z2;
}

.u-z3 {
  z-index: $z3;
}

.u-z4 {
  z-index: $z4;
}

/* Opacity
------------------------------------ */
.o0 {
  opacity: 0;
}

.o1 {
  opacity: 1;
}

.gray--section {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  padding: 20px;
  border: 1px solid #e8e8e8;
  background: #f6f7fa;
  cursor: pointer;
  border-radius: 4px;
}

.horizontal--section {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px;
  border: 1px solid #e8e8e8;
  background-color: white;
  cursor: pointer;
  border-radius: 4px;
}

.gray--highlight {
  display: inline-block;
  padding: 6px;
  border: 1px solid #d8dadb;
  background: #f5f6f9;
  font-size: .88rem;
  cursor: pointer;
  border-radius: 5px;
}

.square {
  display: inline-block;
  width: 18px;
  height: 18px;
  border: solid 1px #d8dadb;
  background-color: #f5f6f9;
  text-align: center;
  font-size: .88rem;
  color: #888d8f;
  border-radius: 5px;
}

.upload-image--container {
  position: absolute;
  left: 0px;
  width: 115px;
  height: 115px;
  border: 2px dashed #e8e8e8;
  background-color: #f6f7fa;
}

.image--container {
  position: relative;
  width: 115px;
  height: 115px;
}

.CustomizeBox__asset-item {
  display: inline-block;
  vertical-align: top;
}

.CustomizeBox__asset {
  width: 100%;
  border: 1px solid $color-border-gray;
  border-radius: $radius;
}

.HiddenElement {
  visibility: hidden;
}

.AlignColumnStart {
  display: flex;
  align-content: start;
  align-items: start;
  flex-direction: column;
}

.AlignColumnCenter {
  display: flex;
  align-content: center;
  flex-direction: column;
}

/* Border
------------------------------------ */
.b-t--nil {
  border-top: 0px !important;
}

.b--gray {
  border: 1px solid $color-border-gray;
}

/* Transition helpers
------------------------------------ */
.fade-out {
  opacity:0;
  transition: opacity 1s;
}

.deleteIcon {
  position: absolute;
  background: image-url('icons/delete-icon.png') no-repeat 0 0;
  opacity: 0;
  
  .deleteIcon:hover {
    opacity: 1;
  }
}

// Height
.maxHeight {
  max-height: 100%;
}

// for mobile screen
@media (max-width: 800px) {
  .u-ml--med-mobile {
    margin-left: 20px;
  }

  .u-mt--med-mobile {
    margin-top: 20px;
  }

  .u-mr--med-mobile {
    margin-right: 20px;
  }

  .u-mb--med-mobile {
    margin-bottom: 20px;
  }
}